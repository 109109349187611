
export class DeploymentStatusSummaries {
    deploymentName = '';
    deploymentSummaries: DeploymentStatusSummary[] = [];

    constructor(fields: {
        deploymentSummaries?: IDeploymentStatusSummary[]
    }) {
        if (fields) {
            Object.assign(this, fields);
        }

        if (fields.deploymentSummaries) {
            if (fields.deploymentSummaries.length > 0) {
                this.deploymentName = fields.deploymentSummaries[0].deploymentName || '';
            }
            this.deploymentSummaries = fields.deploymentSummaries.filter(x => x.tenantStatusUpdates?.length || 0 > 0).map((x: IDeploymentStatusSummary) => new DeploymentStatusSummary(x));
        }
    }
}

export class DeploymentStatusSummary implements IDeploymentStatusSummary {
    deploymentName = '';
    statusDate = '';
    codeVersion = '';
    dbVersion = '';
    deploymentStatusUpdateId = 0;
    deploymentId = 0;
    tenantStatusUpdates: TenantStatus[] = [];

    constructor(fields: IDeploymentStatusSummary) {
        if (fields) {
            Object.assign(this, fields);
        }

        if (fields.tenantStatusUpdates) {
            this.tenantStatusUpdates = fields.tenantStatusUpdates.map((x: ITenantStatus) => new TenantStatus(x));
        }
    }
}

export interface IDeploymentStatusSummary {
    deploymentName?: string;
    statusDate?: string;
    codeVersion?: string;
    dbVersion?: string;
    deploymentStatusUpdateId?: number;
    deploymentId?: number;
    tenantStatusUpdates?: ITenantStatus[];
}


export class TenantStatus implements ITenantStatus {
    name = '';
    subjectCount = 0;
    userCount = 0;
    isCurrentDb = false;
    dbVersion = '';
    modifiedDate = '';
    dataValidationResult = '';

    constructor(fields: ITenantStatus) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

export interface ITenantStatus {
    name?: string;
    subjectCount?: number;
    userCount?: number;
    isCurrentDb?: boolean;
    dbVersion?: string;
    modifiedDate?: string;
    dataValidationResult?: string;
}
