<div class="content"
     role="main">
    <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments">
                        <svg class="bi"
                             width="16"
                             height="16">
                            <use xlink:href="#house-door-fill"></use>
                        </svg>
                        <span class="">Deployments</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments/{{deploymentId}}">{{deploymentName}}</a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="link-body-emphasis fw-normal">
                        Tenant Databases
                    </span>
                </li>
            </ol>
        </nav>
    </div>
    <!-- <h2 class="pageName">Tenant Databases</h2> -->
    <!-- <pre>Tenants list has: *{{ tenants | json }}*</pre> -->
    <div class="menu">
        <button class="btn btn-sm btn-outline-primary float-end"
                (click)="upgradeAllTenantDbs()">Upgrade All DBs</button>
    </div>
    <div class="tenantsTable">
        <table>
            <thead>
                <tr>
                    <th>Tenant</th>
                    <th>UID</th>
                    <th>Db Version</th>
                    <th>Db Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tenant of tenants">
                    <td>{{ tenant.name }}</td>
                    <td>{{ tenant.uid }}</td>
                    <td>{{ !tenant.databaseVersion || tenant.databaseVersion === '' ? '---' : tenant.databaseVersion }}
                    <td>{{ !tenant.databaseSize || tenant.databaseSize === '' ? '---' : tenant.databaseSize }}</td>
                    <td>
                        <button class="btn btn-link"
                                (click)="upgradeTenantDb(tenant)">
                            Upgrade Database
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
