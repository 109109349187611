import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-four04',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.scss']
})
export class Four04Component implements OnInit {
  msg = '';
  constructor(private _route: ActivatedRoute) { }


    ngOnInit(): void {
      this.msg = this._route.snapshot.queryParams['msg'];
    }


}
