<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
    :host {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 8px 0;
    }

    p {
        margin: 0;
    }

    .spacer {
        flex: 1;
    }

    .spacer-middle {
        flex: 4;
    }

    .toolbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        display: flex;
        align-items: center;
        background-color: #1976d2;
        color: white;
        font-weight: 600;
    }

    .toolbar img {
        margin: 0 16px;
    }

    .toolbar #logout-link {
        height: 40px;
        margin: 0px 15px;
        padding-top: 18px;
        color: white;
    }

    .toolbar #deployments-link {
        height: 40px;
        margin: 0px 15px;
        padding-top: 18px;
        color: white;
    }

    .toolbar #logout-link:hover,
    .toolbar #deployments-link:hover {
        opacity: 0.8;
    }

    a,
    a:visited,
    a:hover {
        color: #1976d2;
        text-decoration: none;
    }

    a:hover {
        color: #125699;
    }
</style>

<!-- Toolbar -->
<div class="toolbar"
     role="banner">
    <div class="spacer"></div>

    <img width="30"
         alt="Noble Logo"
         src="/assets/logo.svg"
         routerLink="/deployments" />
    <span routerLink="/deployments">Nucleus</span>
    <div class="spacer-middle"></div>
    <button class="btn btn-sm btn-primary" *ngIf="!loginDisplay" (click)="login()">Login</button>
    <button class="btn btn-sm btn-primary" *ngIf="loginDisplay" (click)="logout()">Logout</button>
    <div class="spacer"></div>
</div>
<div *ngIf="isRequestPending"
     class="loading-indicator">
    <div class="loading-bar"></div>
</div>

<div class="content">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
