import * as _ from 'lodash-es';
import { ITenantSummary, TenantSummary } from './tenant';

export class DeploymentSummaries {

    public deployments: DeploymentSummary[];
    constructor(json: IDeploymentSummary[]) {
        // TODO: update api to return an object instead of an array.
        if (json && json.length > 0) {
            this.deployments = _.map(json, (item: IDeploymentSummary) => {
                return new DeploymentSummary(item);
            });
        } else {
            this.deployments = [];
        }

    }
}

export class DeploymentSummary implements IDeploymentSummary {
    public deploymentId = 0;
    public name = '';
    public uid = '';

    constructor(fields?: IDeploymentSummary) {
        if (fields) {
            Object.assign(this, fields);
        }
    }

    isNew(): boolean {
        return !this.deploymentId;
    }
}

export interface IDeploymentSummary {
    deploymentId?: number;
    name?: string;
    uid?: string;
}

export class DeploymentDetail {
    public deploymentId = 0;
    public name = '';
    public uid = '';
    public tenants: TenantSummary[] = [];

    constructor(fields?: IDeploymentDetail) {
        if (fields) {
            Object.assign(this, fields);

            if (fields.tenants && fields.tenants.length > 0) {
                for (let i = 0; i < fields.tenants.length; i++) {
                    this.tenants[i] = new TenantSummary(fields.tenants[i]);
                }
            }
        }
    }

    isNew(): boolean {
        return !this.deploymentId;
    }
}

export interface IDeploymentDetail {
    deploymentId?: number;
    name?: string;
    uid?: string;
    tenants?: ITenantSummary[];
}
