import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Four04Component } from './four04/four04.component';
import { DeploymentListComponent } from './deployment-list/deployment-list.component';
import { DeploymentFormComponent } from './deployment-form/deployment-form.component';
import { DeploymentsService } from './services/deployments.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TenantFormComponent } from './tenant-form/tenant-form.component';
import { TenantsService } from './services/tenants.service';
import { TenantDbsComponent } from './tenant-dbs/tenant-dbs.component';
import { DeploymentStatusListComponent } from './deployment-status-list/deployment-status-list.component';
import { RequestStatusService } from './services/request-status.service';
import { RequestInterceptor } from './services/request.interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { environment } from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({ declarations: [
        AppComponent,
        Four04Component,
        DeploymentListComponent,
        DeploymentFormComponent,
        TenantFormComponent,
        TenantDbsComponent,
        DeploymentStatusListComponent,
        HomeComponent,
        ProfileComponent
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.azureClientId, // Application (client) ID from the app registration
                authority: `https://login.microsoftonline.com/${environment.azureTenantId}`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                redirectUri: environment.redirectUri, // This is your redirect URI
                postLogoutRedirectUri: environment.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ["user.read"],
            },
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                ["/api/", [`api://${environment.azureClientId}/access_as_user`]],
            ]),
        })], providers: [
        DeploymentsService,
        TenantsService,
        RequestStatusService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
            deps: [RequestStatusService],
        },
        provideNgxMask(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
