import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, finalize } from 'rxjs';
import { RequestStatusService } from './request-status.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private _requestService: RequestStatusService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this._requestService.requestStarted();
        return next.handle(request).pipe(
            catchError((error) => {

                // Handle 401 and 403 errors by redirecting to the login page
                if (error?.status === 401 || error?.status === 403) {
                    const msg = 'You do not have permission to use Nucleus';
                    document.location = `/unauthorized?msg=${msg}`;
                }

                throw error;
            }),
            finalize(() => {
                this._requestService.requestEnded();
            })
        );
    }
}
