<div class="content"
     role="main">
    <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments">
                        <span class="">Deployments</span>
                    </a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="link-body-emphasis fw-normal text-decoration-none">{{deployment?.name}}</span>
                </li>
            </ol>
        </nav>
    </div>
    <div class="form-body">
        <!-- <h2>Deployment Details</h2> -->
        <form *ngIf="deployment"
              name="deploymentForm"
              class="deployent-form">

            <div class="form-group">
                <label for="name">Name</label>
                <input class="form-control"
                       [(ngModel)]="deployment.name"
                       name="name"
                       required />
            </div>

            <div class="form-group">
                <label for="uid">UID<span *ngIf="deployment.isNew()"> (optional)</span>:</label>
                <input class="form-control"
                       name="uid"
                       [disabled]="!deployment.isNew()"
                       [(ngModel)]="deployment.uid" />
            </div>
            <div class="menu">
                <button class="btn btn-sm btn-primary"
                        (click)="save()">Save</button>
            </div>
        </form>
    </div>


    <div *ngIf="!deployment?.isNew()"
         class="tenants-container">
        <h3 class="pageName">Manage Tenants</h3>
        <!-- <pre>Tenant list has: *{{ deployment?.tenants | json }}*</pre> -->
        <div class="menu">
            <button class="btn btn-sm btn-primary"
                    routerLink="/deployments/{{ deployment?.deploymentId }}/tenants/new"
                    [queryParams]="{ deploymentName: deployment?.name }">+ Add</button>
            <button class="btn btn-sm btn-outline-primary float-end btn-pad-left"
                    routerLink="/deployments/{{ deployment?.deploymentId }}/status-list">Status Updates</button>
                    <button class="btn btn-sm btn-outline-primary float-end btn-pad-left"
                    (click)="upgradeAllTenantDbs()">Upgrade All Tenant DBs</button>
            <button class="btn btn-sm btn-outline-primary float-end"
                    routerLink="/deployments/{{ deployment?.deploymentId }}/tenants-dbs"
                    [queryParams]="{ deploymentName: deployment?.name }">Tenant DBs</button>
        </div>
        <div *ngIf="deployment?.tenants"
             class="tenantTable">
             <input type="text" class="form-control" placeholder="Search" [(ngModel)]="tenantSearchText"
                (keyup)="filterTenants()" />
            <table>
                <thead>
                    <tr>
                        <th>Tenant Name</th>
                        <th>Friendly Name</th>
                        <th>Status</th>
                        <th>UID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tenant of filteredTenants">
                        <td>
                            <button class="btn btn-link"
                                    routerLink="/deployments/{{ deployment?.deploymentId }}/tenants/{{ tenant.tenantId }}">
                                {{ tenant.name }}
                            </button>
                        </td>
                        <td>{{ tenant.friendlyName }}</td>
                        <td [class.is-disabled]="tenant.isDisabled">{{ tenant.isDisabled ? "Disabled" : "Active" }}</td>
                        <td>{{ tenant.uid }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!deployment?.tenants">
            <p>No tenants found.</p>
        </div>
    </div>

    <div *ngIf="!deployment?.isNew()"
         class="danger-zone alert alert-danger">
        <button class="btn btn-danger"
                (click)="delete()">Delete Deployment</button>
    </div>

</div>
