import * as _ from 'lodash-es';
import * as uuid from 'uuid';
export class TenantSummaries {

    public tenants: TenantSummary[];
    constructor(fields?: {
        tenants?: ITenantSummary[];
    }) {
        // TODO: update api to return an object instead of an array.
        if (fields && fields.tenants) {
            this.tenants = _.map(fields.tenants, (item: ITenantSummary) => {
                return new TenantSummary(item);
            });
        } else {
            this.tenants = [];
        }
    }
}

export class TenantSummary implements ITenantSummary {
    public tenantId = 0;
    public deploymentId = 0;
    public name = '';
    public friendlyName = '';
    public uid = '';
    public databaseVersion = '';
    public databaseSize = '';
    public isDisabled = false;

    constructor(fields?: ITenantSummary) {
        if (fields) {
            Object.assign(this, fields);
        }
    }

    isNew(): boolean {
        return !this.tenantId;
    }
}

export interface ITenantSummary {
    tenantId?: number;
    deploymentId?: number;
    name?: string;
    friendlyName?: string;
    uid?: string;
    databaseVersion?: string;
    databaseSize?: string;
    isDisabled?: boolean;
}

export class TenantDetail implements ITenantDetail {
    tenantId = 0;
    deploymentId = 0;
    uid = '';
    name = '';
    deploymentName = '';
    friendlyName = '';
    formConfigurationText = '[]';
    triggerConfigurationText = '[]';
    tenantConfiguration: TenantConfiguration = new TenantConfiguration();

    constructor(fields?: ITenantDetail) {
        if (fields) {
            Object.assign(this, fields);

            if (fields.tenantConfiguration) {
                this.tenantConfiguration = new TenantConfiguration(fields.tenantConfiguration);
            }
        }
    }

    isNew(): boolean {
        return !this.tenantId;
    }

}

export interface ITenantDetail {
    tenantId?: number;
    deploymentId?: number;
    uid?: string;
    name?: string;
    deploymentName?: string;
    friendlyName?: string;
    formConfigurationText?: string;
    triggerConfigurationText?: string;
    tenantConfiguration?: ITenantConfiguration;
}


export class TenantConfiguration implements ITenantConfiguration {
    //  RuntimeValidationStatus: string;
    //  EnforceNoDuplicates: string;
    //  EnforceNoMultipleRadios: string;
    //  TenantName: string;
    adultJuvenileMode = 1;
    integrationConfiguration: IntegrationConfiguration = new IntegrationConfiguration();
    ssrsConfiguration: SSRSConfiguration = new SSRSConfiguration();
    eidAlias = '';
    subjectMultiAccountMode = 0;
    defaultStateAbbreviation = '';
    prepopulationDaysCutoff = 180;
    dbConnectionString = '';
    customGoalGuid = uuid.v4();
    customInterventionGuid = uuid.v4();
    //  FormConfigurations: FormConfiguration[];
    //  JobTriggers: JobTrigger[];
    emailNotificationsEnabled = false;
    emailNotificationsWhitelist = '';
    emailNotificationsAddressList = '';
    hideNonQualifiedChargesByDefault = false;
    showChargeFilterButtons = false;
    showSubjectAttachments = false;
    hideSubjectCrimNeedsOnProfile = false;
    showGoalMeasurementOfProgress = false;
    showGoalExtentOfCompliance = false;
    showGoalCasePlanAssignment = false;
    showCasePlanDriver = false;
    hideAssessments = false;
    showReferrals = false;
    casePlanButtonNameOverride = '';
    systemCustomBrandingText = '';
    systemAccountAlias = '';
    systemSubjectAlias = '';
    systemCaseAlias = '';
    systemSanctionAlias = '';
    mailServerEnabled = false;
    mailServerHost = '';
    mailServerFrom = '';
    mailServerPort = 0;
    mailServerUserName = '';
    mailServerPassword = '';
    mailServerEnableSsl = false;
    userManualLink = '';
    analyticsDisabled = false;
    accountMouEnabled = false;
    caseManagerAlias = '';
    assessmentTabAlias = '';
    generalFormTabAlias = '';
    generalFormTabLayout = '';
    twilioEnabled = false;
    twilioPhoneNumber = '';
    localizationLocaleCode = '';
    offenseManagementDisabled = false;
    maxFileUploadSizeBytes = 0;
    isDisabled = false;

    constructor(fields?: ITenantConfiguration) {
        if (fields) {
            Object.assign(this, fields);

            if (fields.integrationConfiguration) {
                this.integrationConfiguration = new IntegrationConfiguration(fields.integrationConfiguration);
            }

            if (fields.ssrsConfiguration) {
                this.ssrsConfiguration = new SSRSConfiguration(fields.ssrsConfiguration);
            }
        }
    }

}

export interface ITenantConfiguration {
    adultJuvenileMode?: number;
    subjectMultiAccountMode?: number;
    eidAlias?: string;
    defaultStateAbbreviation?: string;
    localizationLocaleCode?: string;
    prepopulationDaysCutoff?: number;
    dbConnectionString?: string;
    customGoalGuid?: string;
    customInterventionGuid?: string;
    emailNotificationsEnabled?: boolean;
    emailNotificationsWhitelist?: string;
    emailNotificationsAddressList?: string;
    offenseManagementDisabled?: boolean;
    accountMouEnabled?: boolean;
    hideAssessments?: boolean;
    showReferrals?: boolean;
    hideNonQualifiedChargesByDefault?: boolean;
    showChargeFilterButtons?: boolean;
    showSubjectAttachments?: boolean;
    hideSubjectCrimNeedsOnProfile?: boolean;
    showGoalMeasurementOfProgress?: boolean;
    showGoalExtentOfCompliance?: boolean;
    showGoalCasePlanAssignment?: boolean;
    showCasePlanDriver?: boolean;
    analyticsDisabled?: boolean;
    mailServerEnabled?: boolean;
    mailServerHost?: string;
    mailServerFrom?: string;
    mailServerPort?: number;
    mailServerUserName?: string;
    mailServerPassword?: string;
    mailServerEnableSsl?: boolean;
    userManualLink?: string;
    caseManagerAlias?: string;
    assessmentTabAlias?: string;
    generalFormTabAlias?: string;
    generalFormTabLayout?: string;
    casePlanButtonNameOverride?: string;
    systemCustomBrandingText?: string;
    systemAccountAlias?: string;
    systemSubjectAlias?: string;
    systemCaseAlias?: string;
    systemSanctionAlias?: string;
    integrationConfiguration?: IIntegrationConfiguration;
    ssrsConfiguration?: ISSRSConfiguration;
    isDisabled?: boolean;
}

export class IntegrationConfiguration implements IIntegrationConfiguration {
    integrationEnabled = false;
    authenticationEnabled = false;
    userIntegrationEnabled = false;
    subjectIntegrationEnabled = false;
    subjectSearchIntegrationEnabled = false;
    chargesIntegrationEnabled = false;
    casesIntegrationEnabled = false;
    profilePhotoIntegrationEnabled = false;
    serviceUri = '';
    alternateTokenAuthenticationUri = '';
    serviceUserName = '';
    servicePassword = '';
    httpBasicAuthenticationUserName = '';
    httpBasicAuthenticationPassword = '';
    serviceName = '';
    serviceContact = '';
    serviceEmail = '';
    servicePhone = '';
    allowInvalidSslCertificate = false;
    integrationConsumerType = '';
    postFormResultsOnComplete = false;
    postCasePlanResultsOnComplete = false;
    maxReceivedMessageSize = 0;

    constructor(fields?: IIntegrationConfiguration) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

export interface IIntegrationConfiguration {
    integrationEnabled?: boolean;
    authenticationEnabled?: boolean;
    userIntegrationEnabled?: boolean;
    subjectIntegrationEnabled?: boolean;
    subjectSearchIntegrationEnabled?: boolean;
    chargesIntegrationEnabled?: boolean;
    casesIntegrationEnabled?: boolean;
    profilePhotoIntegrationEnabled?: boolean;
    serviceUri?: string;
    alternateTokenAuthenticationUri?: string;
    serviceUserName?: string;
    servicePassword?: string;
    httpBasicAuthenticationUserName?: string;
    httpBasicAuthenticationPassword?: string;
    serviceName?: string;
    serviceContact?: string;
    serviceEmail?: string;
    servicePhone?: string;
    allowInvalidSslCertificate?: boolean;
    integrationConsumerType?: string;
    postFormResultsOnComplete?: boolean;
    postCasePlanResultsOnComplete?: boolean;
    maxReceivedMessageSize?: number;
}


export class SSRSConfiguration implements ISSRSConfiguration {
    baseUrl = '';
    authType = 0;
    authority = '';
    username = '';
    password = '';
    baseFolder = '';
    aggregateReportsPath = '';
    individualReportsPath = '';
    casePlanReportsPath = '';

    constructor(fields?: ISSRSConfiguration) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

export interface ISSRSConfiguration {
    baseUrl?: string;
    authType?: number;
    authority?: string;
    username?: string;
    password?: string;
    baseFolder?: string;
    aggregateReportsPath?: string;
    individualReportsPath?: string;
    casePlanReportsPath?: string;
}

//export class FormConfiguration {
//  /*
//  [
//                      {
//                        FormUid: string;
//                        Questions: string;
//                        "FormModeOverrides": {
//                          AlwaysIncludeModes: string;
//                          "AlwaysExcludeModes": []
//                        },
//                        ScoreLevelEnabled: string;
//                        ScoreLevelOverrideEnabled: string;
//                        ScoreLevelScoreTargetUid: string;
//                        ScoreLevelLabel: string;
//                        ScoreLevelOverrideLabel: string;
//                        ScoreLevelOverrideManagedListName: string;
//                        "CaseMode": 1
//                      },
//                      {
//                        FormUid: string;
//                        "Questions": [
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": false
//                          }
//                        ],
//                        "FormModeOverrides": {
//                          AlwaysIncludeModes: string;
//                          "AlwaysExcludeModes": []
//                        },
//                        ScoreLevelEnabled: string;
//                        ScoreLevelOverrideEnabled: string;
//                        ScoreLevelScoreTargetUid: string;
//                        ScoreLevelLabel: string;
//                        ScoreLevelOverrideLabel: string;
//                        ScoreLevelOverrideManagedListName: string;
//                        "CaseMode": 0
//                      },
//                      {
//                        FormUid: string;
//                        "Questions": [
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          },
//                          {
//                            Uid: string;
//                            Integrate: string;
//                            "Disable": true
//                          }
//                        ],
//                        "FormModeOverrides": {
//                          AlwaysIncludeModes: string;
//                          "AlwaysExcludeModes": []
//                        },
//                        ScoreLevelEnabled: string;
//                        ScoreLevelOverrideEnabled: string;
//                        ScoreLevelScoreTargetUid: string;
//                        ScoreLevelLabel: string;
//                        ScoreLevelOverrideLabel: string;
//                        ScoreLevelOverrideManagedListName: string;
//                        "CaseMode": 0
//                      },
//                      {
//                        FormUid: string;
//                        Questions: string;
//                        "FormModeOverrides": {
//                          AlwaysIncludeModes: string;
//                          "AlwaysExcludeModes": []
//                        },
//                        ScoreLevelEnabled: string;
//                        ScoreLevelOverrideEnabled: string;
//                        ScoreLevelScoreTargetUid: string;
//                        ScoreLevelLabel: string;
//                        ScoreLevelOverrideLabel: string;
//                        ScoreLevelOverrideManagedListName: string;
//                        "CaseMode": 0
//                      },
//                      {
//                        FormUid: string;
//                        Questions: string;
//                        "FormModeOverrides": {
//                          AlwaysIncludeModes: string;
//                          "AlwaysExcludeModes": []
//                        },
//                        ScoreLevelEnabled: string;
//                        ScoreLevelOverrideEnabled: string;
//                        ScoreLevelScoreTargetUid: string;
//                        ScoreLevelLabel: string;
//                        ScoreLevelOverrideLabel: string;
//                        ScoreLevelOverrideManagedListName: string;
//                        "CaseMode": 2
//                      }
//                    ]
//                    */

//}

//export class JobTrigger {
//  /*
//  [
//                      {
//                        TriggerName: string;
//                        TriggerGroup: string;
//                        JobName: string;
//                        JobGroup: string;
//                        CronExpression: string;
//                        RepeatInterval: string;
//                        RepeatCount: string;
//                        StartDelay: string;
//                        "JobParameters": []
//                      }
//                    ]
//  */
//}
