import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RequestStatusService {

    @Output() isPending = new EventEmitter<boolean>();
    private _pendingRequests = 0;

    requestStarted(): void {

        this.updateStatus(++this._pendingRequests);
    }

    requestEnded(): void {
        this.updateStatus(--this._pendingRequests);
    }

    updateStatus(activeRequests: number): void {
        this.isPending.emit(activeRequests > 0);
    }
}
