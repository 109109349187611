import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ITenantDetail, TenantDetail } from '../models/tenant';

@Injectable({
    providedIn: 'root'
})
export class TenantsService {
    private _api = environment.apiUrl;

    constructor(
        private _http: HttpClient,
    ) { }

    getDeploymentTenant(deploymentId: number, tenantId: number): Observable<TenantDetail> {
        const url = `${this._api}/deployments/${deploymentId}/tenants/${tenantId}?includeConfig=true`;

        return this._http.get<ITenantDetail>(url).pipe(
            map((json: ITenantDetail) => new TenantDetail(json))
        );
    }

    saveDeploymentTenant(deploymentId: number, tenant: TenantDetail): Observable<TenantDetail> {
        const url = `${this._api}/deployments/${deploymentId}/tenants/${tenant.tenantId ? tenant.tenantId : ''}`;
        const body = tenant;

        if (!tenant) {
            throw new Error("tenant is undefined");
        }

        return this._http.post<ITenantDetail>(url, body, {}).pipe(
            map((json: ITenantDetail) => new TenantDetail(json))
        );
    }

    deleteTenant(deploymentId: number, tenantId: number): Observable<unknown> {
        const url = `${this._api}/deployments/${deploymentId}/tenants/${tenantId}`;

        return this._http.delete(url);
    }

    upgradeTenantDb(deploymentId: number, tenantId: number): Observable<unknown> {
        const url = `${this._api}/deployments/${deploymentId}/tenants/${tenantId}/DbUpgrades`;
        const body = {};

        return this._http.post(url, body, {});
    }
}
