import { Injectable } from '@angular/core';
import { DeploymentDetail, DeploymentSummaries, DeploymentSummary, IDeploymentDetail, IDeploymentSummary } from '../models/deployment';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeploymentStatusSummaries, IDeploymentStatusSummary } from '../models/deployment-status';

@Injectable({
    providedIn: 'root'
})
export class DeploymentsService {
    private _api = environment.apiUrl;

    constructor(
        private _http: HttpClient,
    ) { }

    getDeployments(): Observable<DeploymentSummaries> {
        const url = `${this._api}/deployments`;

        return this._http.get<IDeploymentSummary[]>(url).pipe(
            map((json: IDeploymentSummary[]) => new DeploymentSummaries(json))
        );
    }

    getDeployment(deploymentId: number): Observable<DeploymentDetail> {
        const url = `${this._api}/deployments/${deploymentId}`;

        return this._http.get<IDeploymentDetail>(url).pipe(
            map((json: IDeploymentDetail) => new DeploymentDetail(json))
        );
    }

    getDeploymentWithDbStats(deploymentId: number): Observable<DeploymentDetail> {
        const url = `${this._api}/deployments/${deploymentId}?includeDbStats=true`;

        return this._http.get<IDeploymentDetail>(url).pipe(
            map((json: IDeploymentDetail) => new DeploymentDetail(json))
        );
    }


    saveDeployment(deployment: DeploymentSummary | undefined): Observable<DeploymentSummary> {
        const url = `${this._api}/deployments`;
        const body = deployment;

        if (!deployment) {
            throw new Error("deployment is undefined");
        }

        return this._http.post<IDeploymentSummary>(url, body, {}).pipe(
            map((json: IDeploymentSummary) => new DeploymentSummary(json))
        );
    }

    deleteDeployment(deploymentId: number | undefined) {
        const url = `${this._api}/deployments/${deploymentId}`;

        return this._http.delete(url);
    }

    upgradeAllTenantDbs(deploymentId: number): Observable<unknown> {
        const url = `${this._api}/deployments/${deploymentId}/DbUpgrades`;
        const body = {};

        return this._http.post(url, body, {});
    }

    getDeploymentStatusSummaries(deploymentId: number): Observable<DeploymentStatusSummaries> {
        const url = `${this._api}/deployments/${deploymentId}/StatusUpdates`;

        return this._http.get<IDeploymentStatusSummary[]>(url).pipe(
            map((json: IDeploymentStatusSummary[]) => new DeploymentStatusSummaries({ deploymentSummaries: json }))
        );
    }

    deleteDeploymentStatusUpdate(deploymentId: number, deploymentStatusUpdateId: number): Observable<DeploymentStatusSummaries> {
        const url = `${this._api}/deployments/${deploymentId}/StatusUpdates/${deploymentStatusUpdateId}`;

        return this._http.delete<IDeploymentStatusSummary[]>(url).pipe(
            map((json: IDeploymentStatusSummary[]) => new DeploymentStatusSummaries({ deploymentSummaries: json }))
        );
    }

    deleteAllDeploymentStatusUpdates(deploymentId: number): Observable<DeploymentStatusSummaries> {
        const url = `${this._api}/deployments/${deploymentId}/StatusUpdates`;

        return this._http.delete<IDeploymentStatusSummary[]>(url).pipe(
            map((json: IDeploymentStatusSummary[]) => new DeploymentStatusSummaries({ deploymentSummaries: json }))
        );
    }


}
