import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeploymentStatusSummaries, DeploymentStatusSummary } from '../models/deployment-status';
import { DeploymentsService } from '../services/deployments.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-deployment-status-list',
    templateUrl: './deployment-status-list.component.html',
    styleUrls: ['./deployment-status-list.component.scss']
})
export class DeploymentStatusListComponent implements OnInit, OnDestroy {
    statusSummaries: DeploymentStatusSummary[] = [];
    deploymentId: number | undefined;
    deploymentName = '';

    private subs = new SubSink();

    constructor(
        private _route: ActivatedRoute,
        private _deploymentService: DeploymentsService
    ) { }

    ngOnInit(): void {
        this.deploymentId = this._route.snapshot.params['id'];

        this.subs.sink = this._route.data.subscribe(({ summaries }) => {
            if (summaries) {
                this.deploymentName = summaries.deploymentName;
                this.statusSummaries = summaries.deploymentSummaries;
            }
        });
    }
    deleteAllStatusSummaries() {
        if (!this.deploymentId) {
            throw new Error("deploymentId is undefined");
        }
        if (confirm(`Are you sure you want to delete all status updates for deployment ${this.deploymentName}?`)) {
            this.subs.sink = this._deploymentService.deleteAllDeploymentStatusUpdates(this.deploymentId).subscribe((summaries) => {
                // reload this view somehow...
                this.statusSummaries = summaries.deploymentSummaries;
            })
        }
    }

    deleteStatusSummary(statusSummary: DeploymentStatusSummary) {
        if (!this.deploymentId) {
            throw new Error("deploymentId is undefined");
        }

        this.subs.sink = this._deploymentService.deleteDeploymentStatusUpdate(this.deploymentId, statusSummary.deploymentStatusUpdateId).subscribe((summaries: DeploymentStatusSummaries) => {
            // reload this view somehow...
            this.statusSummaries = summaries.deploymentSummaries;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
