import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Four04Component } from './four04/four04.component';
import { DeploymentListComponent } from './deployment-list/deployment-list.component';
import { DeploymentFormComponent } from './deployment-form/deployment-form.component';
import { deploymentListResolver } from './deployment-list/deployment-list.resolver';
import { deploymentDetailResolver } from './deployment-form/deployment-form.resolver';
import { TenantFormComponent } from './tenant-form/tenant-form.component';
import { tenantDetailResolver } from './tenant-form/tenant-detail.resolver';
import { TenantDbsComponent } from './tenant-dbs/tenant-dbs.component';
import { tenantDbsResolver } from './tenant-dbs/tenant-dbs.resolver';
import { DeploymentStatusListComponent } from './deployment-status-list/deployment-status-list.component';
import { deploymentStatusListResolver } from './deployment-status-list/deployment-status-list.resolver';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'deployments',
    component: DeploymentListComponent,
    resolve: {
      deployments: deploymentListResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/new',
    component: DeploymentFormComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/:id',
    component: DeploymentFormComponent,
    resolve: {
      deployment: deploymentDetailResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/:id/tenants/:tenantId',
    component: TenantFormComponent,
    resolve: {
      tenant: tenantDetailResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/:id/tenants/new',
    component: TenantFormComponent,
    resolve: {
      tenant: tenantDetailResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/:id/tenants-dbs',
    component: TenantDbsComponent,
    resolve: {
      deployment: tenantDbsResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'deployments/:id/status-list',
    component: DeploymentStatusListComponent,
    resolve: {
      summaries: deploymentStatusListResolver
    },
    canActivate: [MsalGuard],
  },
  {
    path: "profile",
    component: ProfileComponent,
  },

  // Server error routes
  {
    path: '**',
    component: Four04Component
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
