import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { TenantsService } from '../services/tenants.service';
import { TenantDetail } from '../models/tenant';
import { of } from 'rxjs';

export const tenantDetailResolver: ResolveFn<TenantDetail> = (route) => {
    const deploymentId = route.params['id'];
    const tenantId = route.params['tenantId'];

    if (!tenantId || tenantId === 'new') {
        const deploymentName = route.queryParams['deploymentName'];
        return of(new TenantDetail({ deploymentId, deploymentName }));
    }

    return inject(TenantsService).getDeploymentTenant(deploymentId, tenantId);
};
