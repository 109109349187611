<div class="content"
     role="main">
    <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments">
                        <svg class="bi"
                             width="16"
                             height="16">
                            <use xlink:href="#house-door-fill"></use>
                        </svg>
                        <span class="">Deployments</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments/{{deploymentId}}">{{tenant?.deploymentName}}</a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="link-body-emphasis fw-normal">
                        Tenant: {{tenant?.name ? tenant?.name : 'new'}}
                    </span>
                </li>
            </ol>
        </nav>
    </div>
    <div class="menu">
        <button class="btn btn-sm btn-outline-primary float-end json-preview-btn"
                (click)="showJson = !showJson">JSON</button>
        <button class="btn btn-sm btn-primary upgrade-btn float-end"
                (click)="upgradeTenantDb()">
            Upgrade Database
        </button>

    </div>
    <div class="row form-body">
        <!-- <h2>Tenant Details</h2> -->
        <div class="col-md-{{showJson ? '6' : '12'}}">
            <form *ngIf="tenant"
                  name="tenantForm"
                  class="tenant-form">

                <div class="form-group">
                    <label for="tenantName">Tenant Name (no spaces)</label>
                    <input id="tenantName"
                           class="form-control"
                           [dropSpecialCharacters]="false"
                           [patterns]="namePatterns"
                           mask="P*"
                           [(ngModel)]="tenant.name"
                           name="name"
                           required />
                </div>

                <div class="checkbox">
                    <label for="disableTenant">
                        <input id="disableTenant"
                               [(ngModel)]="tenant.tenantConfiguration.isDisabled"
                               name="disableTenant"
                               type="checkbox" />Disable Tenant
                    </label>
                </div>

                <div class="form-group">
                    <label for="friendlyName">Friendly Name</label>
                    <input id="friendlyName"
                           class="form-control"
                           [(ngModel)]="tenant.friendlyName"
                           name="friendlyName"
                           required />
                </div>

                <div class="form-group">
                    <label for="tenant-uid">UID<span *ngIf="tenant.isNew()"> (optional)</span>:</label>
                    <input for="tenant-uid"
                           class="form-control"
                           name="uid"
                           [disabled]="!tenant.isNew()"
                           [(ngModel)]="tenant.uid" />
                </div>

                <div class="form-group">
                    <label for="ajMode">Adult/Juvenile Mode</label>
                    <select class="form-control"
                            #ajMode
                            [(ngModel)]="tenant.tenantConfiguration.adultJuvenileMode"
                            (ngModelChange)="tenant.tenantConfiguration.adultJuvenileMode = +ajMode.value"
                            name="ajMode"
                            required>
                        <option name="juvenile"
                                value="1">
                            Juvenile
                        </option>
                        <option name="adult"
                                value="2">
                            Adult
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="smaMode">Subject Multi-Account Mode</label>
                    <select id="smaMode"
                            class="form-control"
                            [(ngModel)]="tenant.tenantConfiguration.subjectMultiAccountMode"
                            name="smaMode"
                            required>
                        <option name="SingleAccountEnforced"
                                value="0">
                            Single-Account Enforced
                        </option>
                        <option name="MultiAccountAllowed"
                                value="1">
                            Multi-Account Allowed
                        </option>
                        <option name="MultiAccountAllowedActiveInSingleEnforced"
                                value="2">
                            Multi-Account Allowed Active Single Enforced
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="eidAlias">EID Alias</label>
                    <input id="eidAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.eidAlias"
                           name="eidAlias"
                           required />
                </div>

                <div class="form-group">
                    <label for="defaultState">Default State (two letter abbrev.)</label>
                    <input id="defaultState"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.defaultStateAbbreviation"
                           name="defaultState"
                           required />
                </div>

                <div class="form-group">
                    <label for="localeCode">Locale</label>
                    <select id="localeCode"
                            class="form-control"
                            [(ngModel)]="tenant.tenantConfiguration.localizationLocaleCode"
                            name="localeCode"
                            required>
                        <option name="enus"
                                value="en-US">
                            English United States (en-US)
                        </option>
                        <option name="engb"
                                value="en-GB">
                            English Great Britain (en-GB)
                        </option>
                        <option name="et"
                                value="et">
                            Estonian (et)
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="prepopCutoff">Pre-population Cutoff (in days)</label>
                    <input id="prepopCutoff"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.prepopulationDaysCutoff"
                           name="prepopCutoff"
                           type="number"
                           required />
                </div>

                <div class="form-group">
                    <label for="dbConn">DB Connection String</label>
                    <textarea id="dbConn"
                              class="form-control"
                              [(ngModel)]="tenant.tenantConfiguration.dbConnectionString"
                              name="dbConnectionString"
                              rows="4"
                              cols="200">
          </textarea>
                </div>

                <div class="form-group">
                    <label for="customGoalGuid">Custom Goal GUID</label>
                    <input id="customGoalGuid"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.customGoalGuid"
                           name="customGoalGuid"
                           required />
                </div>

                <div class="form-group">
                    <label for="customInterventionGuid">Custom Intervention GUID</label>
                    <input id="customInterventionGuid"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.customInterventionGuid"
                           name="customInterventionGuid"
                           required />
                </div>

                <div class="checkbox">
                    <label for="enableEmailNotifications">
                        <input id="enableEmailNotifications"
                               [(ngModel)]="tenant.tenantConfiguration.emailNotificationsEnabled"
                               name="enableEmailNotifications"
                               type="checkbox" />Enable Email Notifications
                    </label>
                </div>

                <div class="form-group"
                     [hidden]="!tenant.tenantConfiguration.emailNotificationsEnabled">
                    <label for="emailNotificationsWhitelist">
                        Email Notification Whitelist (example:
                        noblesg.com,otherdomain.com),
                        leave blank to allow all
                    </label>
                    <input id="emailNotificationsWhitelist"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.emailNotificationsWhitelist"
                           name="emailNotificationsWhitelist" />
                </div>

                <div class="form-group"
                     [hidden]="!tenant.tenantConfiguration.emailNotificationsEnabled">
                    <label for="emailNotificationsAddressList">Email Notification Addresses</label>
                    <input id="emailNotificationsAddressList"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.emailNotificationsAddressList"
                           name="emailNotificationsAddressList" />
                </div>

                <div class="checkbox">
                    <label for="OffenseManagementDisabled">
                        <input id="OffenseManagementDisabled"
                               [(ngModel)]="tenant.tenantConfiguration.offenseManagementDisabled"
                               name="OffenseManagementDisabled"
                               type="checkbox" />Disable usage of Offense Management System
                    </label>
                </div>

                <div class="checkbox">
                    <label for="AccountMouEnabled">
                        <input id="AccountMouEnabled"
                               [(ngModel)]="tenant.tenantConfiguration.accountMouEnabled"
                               name="AccountMouEnabled"
                               type="checkbox" />Enable Account MOU Requirement
                    </label>
                </div>

                <div class="checkbox">
                    <label for="HideAssessments">
                        <input id="HideAssessments"
                               [(ngModel)]="tenant.tenantConfiguration.hideAssessments"
                               name="HideAssessments"
                               type="checkbox" />Hide Subject Assessments
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowReferrals">
                        <input id="ShowReferrals"
                               [(ngModel)]="tenant.tenantConfiguration.showReferrals"
                               name="ShowReferrals"
                               type="checkbox" />Show Subject Referrals
                    </label>
                </div>

                <div class="checkbox">
                    <label for="HideNonQualifiedChargesByDefault">
                        <input id="HideNonQualifiedChargesByDefault"
                               [(ngModel)]="tenant.tenantConfiguration.hideNonQualifiedChargesByDefault"
                               name="HideNonQualifiedChargesByDefault"
                               type="checkbox" />Hide Non-Qualified Charges By Default
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowChargeFilterButtons">
                        <input id="ShowChargeFilterButtons"
                               [(ngModel)]="tenant.tenantConfiguration.showChargeFilterButtons"
                               name="ShowChargeFilterButtons"
                               type="checkbox" />Show Charge Filter Buttons
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowSubjectAttachments">
                        <input id="ShowSubjectAttachments"
                               [(ngModel)]="tenant.tenantConfiguration.showSubjectAttachments"
                               name="ShowSubjectAttachments"
                               type="checkbox" />Show Subject Attachments
                    </label>
                </div>

                <div class="checkbox">
                    <label for="HideSubjectCrimNeedsOnProfile">
                        <input id="HideSubjectCrimNeedsOnProfile"
                               [(ngModel)]="tenant.tenantConfiguration.hideSubjectCrimNeedsOnProfile"
                               name="HideSubjectCrimNeedsOnProfile"
                               type="checkbox" />Hide Top 3 Needs On Subject Profile
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowGoalMeasurementOfProgress">
                        <input id="ShowGoalMeasurementOfProgress"
                               [(ngModel)]="tenant.tenantConfiguration.showGoalMeasurementOfProgress"
                               name="ShowGoalMeasurementOfProgress"
                               type="checkbox" />Show All Goal / Intervention Fields (ask Bret for details)
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowGoalExtentOfCompliance">
                        <input id="ShowGoalExtentOfCompliance"
                               [(ngModel)]="tenant.tenantConfiguration.showGoalExtentOfCompliance"
                               name="ShowGoalExtentOfCompliance"
                               type="checkbox" />Show IV-E Placement Case Plan Fields on Interventions (ask Bret for
                        details)
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowGoalCasePlanAssignment">
                        <input id="ShowGoalCasePlanAssignment"
                               [(ngModel)]="tenant.tenantConfiguration.showGoalCasePlanAssignment"
                               name="ShowGoalCasePlanAssignment"
                               type="checkbox" />Show "Case Plan Assignment" on Goals
                    </label>
                </div>

                <div class="checkbox">
                    <label for="ShowCasePlanDriver">
                        <input id="ShowCasePlanDriver"
                               [(ngModel)]="tenant.tenantConfiguration.showCasePlanDriver"
                               name="ShowCasePlanDriver"
                               type="checkbox" />Show Case Plan Driver
                    </label>
                </div>

                <div class="checkbox">
                    <label for="AnalyticsDisabled">
                        <input id="AnalyticsDisabled"
                               [(ngModel)]="tenant.tenantConfiguration.analyticsDisabled"
                               name="AnalyticsDisabled"
                               type="checkbox" />Disable Analytics
                    </label>
                </div>

                <div class="checkbox">
                    <label for="MailServerEnabled">
                        <input id="MailServerEnabled"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerEnabled"
                               name="MailServerEnabled"
                               type="checkbox" />Mail Server Enabled
                    </label>
                </div>

                <div [hidden]="!tenant.tenantConfiguration.mailServerEnabled">
                    <div class="form-group">
                        <label for="MailServerHost">Mail Server Host</label>
                        <input id="MailServerHost"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerHost"
                               name="MailServerHost" />
                    </div>

                    <div class="form-group">
                        <label for="MailServerPort">Mail Server Port</label>
                        <input id="MailServerPort"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerPort"
                               name="MailServerPort"
                               type="number" />
                    </div>

                    <div class="form-group">
                        <label for="MailServerFrom">Mail Server From Address</label>
                        <input id="MailServerFrom"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerFrom"
                               name="MailServerFrom" />
                    </div>

                    <div class="form-group">
                        <label for="MailServerUsername">Mail Server User Name</label>
                        <input id="MailServerUsername"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerUserName"
                               name="MailServerUsername" />
                    </div>

                    <div class="form-group">
                        <label for="MailServerPassword">Mail Server Password</label>
                        <input id="MailServerPassword"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.mailServerPassword"
                               name="MailServerPassword" />
                    </div>

                    <div class="checkbox">
                        <label for="MailServerEnableSsl">
                            <input id="MailServerEnableSsl"
                                   [(ngModel)]="tenant.tenantConfiguration.mailServerEnableSsl"
                                   name="MailServerEnableSsl"
                                   type="checkbox" />Mail Server Enable SSL
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <label for="casePlanButtonNameOverride">Case Plan Button Name Override</label>
                    <input id="casePlanButtonNameOverride"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.casePlanButtonNameOverride"
                           name="casePlanButtonNameOverride" />
                </div>

                <div class="form-group">
                    <label for="caseManagerAlias">Case Manager Alias</label>
                    <input id="caseManagerAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.caseManagerAlias"
                           name="caseManagerAlias" />
                </div>

                <div class="form-group">
                    <label for="assessmentTabAlias">Assessment Tab Alias</label>
                    <input id="assessmentTabAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.assessmentTabAlias"
                           name="assessmentTabAlias" />
                </div>

                <div class="form-group">
                    <label for="generalFormTabAlias">General Form Tab Alias</label>
                    <input id="generalFormTabAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.generalFormTabAlias"
                           name="generalFormTabAlias" />
                </div>

                <div class="form-group">
                    <label for="generalFormTabLayout">General Form Tab Layout</label>
                    <select id="generalFormTabLayout"
                            class="form-control"
                            [(ngModel)]="tenant.tenantConfiguration.generalFormTabLayout"
                            name="generalFormTabLayout"
                            required>
                        <option name="startCards"
                                value="startCards">
                            Start Cards
                        </option>
                        <option name="recordCards"
                                value="recordCards">
                            Record Cards
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="systemCustomBrandingText">Custom Branding Text</label>
                    <input id="systemCustomBrandingText"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.systemCustomBrandingText"
                           name="systemCustomBrandingText" />
                </div>

                <div class="form-group">
                    <label for="systemAccountAlias">Account Alias</label>
                    <input id="systemAccountAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.systemAccountAlias"
                           name="systemAccountAlias" />
                </div>

                <div class="form-group">
                    <label for="systemSubjectAlias">Subject Alias</label>
                    <input id="systemSubjectAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.systemSubjectAlias"
                           name="systemSubjectAlias" />
                </div>

                <div class="form-group">
                    <label for="systemCaseAlias">Case Alias</label>
                    <input id="systemCaseAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.systemCaseAlias"
                           name="systemCaseAlias" />
                </div>

                <div class="form-group">
                    <label for="systemSanctionAlias">Sanction Alias</label>
                    <input id="systemSanctionAlias"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.systemSanctionAlias"
                           name="systemSanctionAlias" />
                </div>

                <div class="form-group">
                    <label for="userManualLink">User Manual Link</label>
                    <input id="userManualLink"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.userManualLink"
                           name="userManualLink" />
                </div>

                <div class="form-group">
                    <label for="maxFileUploadSizeBytes">Max File Upload Size (in bytes)</label>
                    <input id="maxFileUploadSizeBytes"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.maxFileUploadSizeBytes"
                           name="maxFileUploadSizeBytes" />
                </div>

                <hr />
                <h3>Twilio Configuration</h3>

                <div class="checkbox">
                    <label for="twilioEnabled">
                        <input id="twilioEnabled"
                               [(ngModel)]="tenant.tenantConfiguration.twilioEnabled"
                               name="twilioEnabled"
                               type="checkbox" />Twilio Enabled
                    </label>
                </div>

                <div [hidden]="!tenant.tenantConfiguration.twilioEnabled"
                     class="form-group">
                    <label for="twilioPhoneNumber">Twilio Phone Number (example: 15305551111)</label>
                    <input id="twilioPhoneNumber"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.twilioPhoneNumber"
                           name="twilioPhoneNumber" />
                </div>

                <hr />
                <h3>Integration Configuration</h3>

                <div class="checkbox">
                    <label for="integrationEnabled">
                        <input id="integrationEnabled"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.integrationEnabled"
                               name="integrationEnabled"
                               type="checkbox" />Integration Enabled
                    </label>
                </div>

                <div [hidden]="!tenant.tenantConfiguration.integrationConfiguration.integrationEnabled">

                    <div class="checkbox">
                        <label for="authenticationEnabled">
                            <input id="authenticationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.authenticationEnabled"
                                   name="authenticationEnabled"
                                   type="checkbox" />Integration Authentication Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="userIntegrationEnabled">
                            <input id="userIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.userIntegrationEnabled"
                                   name="userIntegrationEnabled"
                                   type="checkbox" />User Integration Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="subjectIntegrationEnabled">
                            <input id="subjectIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.subjectIntegrationEnabled"
                                   name="subjectIntegrationEnabled"
                                   type="checkbox" />Subject Integration Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="subjectSearchIntegrationEnabled">
                            <input id="subjectSearchIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.subjectSearchIntegrationEnabled"
                                   name="subjectSearchIntegrationEnabled"
                                   type="checkbox" />Subject Search Integration Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="chargesIntegrationEnabled">
                            <input id="chargesIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.chargesIntegrationEnabled"
                                   name="chargesIntegrationEnabled"
                                   type="checkbox" />Charges Integration Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="casesIntegrationEnabled">
                            <input id="casesIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.casesIntegrationEnabled"
                                   name="casesIntegrationEnabled"
                                   type="checkbox" />Cases Integration Enabled
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="profilePhotoIntegrationEnabled">
                            <input id="profilePhotoIntegrationEnabled"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.profilePhotoIntegrationEnabled"
                                   name="profilePhotoIntegrationEnabled"
                                   type="checkbox" />Profile Photo Integration Enabled
                        </label>
                    </div>

                    <div class="form-group">
                        <label for="serviceUri">Service URI</label>
                        <input id="serviceUri"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.serviceUri"
                               name="serviceUri" />
                    </div>

                    <div class="form-group">
                        <label for="alternateTokenAuthenticationUri">Alternate Token Auth URI</label>
                        <input id="alternateTokenAuthenticationUri"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.alternateTokenAuthenticationUri"
                               name="alternateTokenAuthenticationUri" />
                    </div>

                    <div class="form-group">
                        <label for="serviceUserName">Service Username</label>
                        <input id="serviceUserName"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.serviceUserName"
                               name="serviceUserName" />
                    </div>

                    <div class="form-group">
                        <label for="servicePassword">Service Password</label>
                        <input id="servicePassword"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.servicePassword"
                               name="servicePassword" />
                    </div>

                    <div class="form-group">
                        <label for="httpBasicAuthenticationUserName">Http Basic Authentication UserName</label>
                        <input id="httpBasicAuthenticationUserName"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.httpBasicAuthenticationUserName"
                               name="httpBasicAuthenticationUserName" />
                    </div>

                    <div class="form-group">
                        <label for="httpBasicAuthenticationPassword">Http Basic Authentication Password</label>
                        <input id="httpBasicAuthenticationPassword"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.httpBasicAuthenticationPassword"
                               name="httpBasicAuthenticationPassword" />
                    </div>

                    <div class="form-group">
                        <label for="serviceName">Service Name</label>
                        <input id="serviceName"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.serviceName"
                               name="serviceName" />
                    </div>

                    <div class="form-group">
                        <label for="serviceContact">Service Contact</label>
                        <input id="serviceContact"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.serviceContact"
                               name="serviceContact" />
                    </div>

                    <div class="form-group">
                        <label for="serviceEmail">Service Email</label>
                        <input id="serviceEmail"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.serviceEmail"
                               name="serviceEmail" />
                    </div>

                    <div class="form-group">
                        <label for="servicePhone">Service Phone</label>
                        <input id="servicePhone"
                               class="form-control"
                               [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.servicePhone"
                               name="servicePhone" />
                    </div>

                    <div class="checkbox">
                        <label for="allowInvalidSslCertificate">
                            <input id="allowInvalidSslCertificate"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.allowInvalidSslCertificate"
                                   name="allowInvalidSslCertificate"
                                   type="checkbox" />Allow Invalid SSL Certificate
                        </label>
                    </div>

                    <div class="form-group">
                        <label for="integrationConsumerType">Integration Consumer Type</label>
                        <select id="integrationConsumerType"
                                class="form-control"
                                [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.integrationConsumerType"
                                name="integrationConsumerType"
                                required>
                            <option name="BexarCountyIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.BexarCountyIntegrationConsumer, NSG.CMS.Integration">
                                Bexar County Integration Consumer
                            </option>
                            <option name="IntegrationConsumer1"
                                    value="NSG.CMS.Integration.Consumers.IntegrationConsumer1, NSG.CMS.Integration">
                                Integration Consumer 1
                            </option>
                            <option name="JcatsIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.JcatsIntegrationConsumer, NSG.CMS.Integration">
                                JCATS Integration Consumer
                            </option>
                            <option name="JsonIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.JsonIntegrationConsumer, NSG.CMS.Integration">
                                JSON Integration Consumer
                            </option>
                            <option name="JsonIntegrationConsumer2"
                                    value="NSG.CMS.Integration.Consumers.JsonIntegrationConsumer2, NSG.CMS.Integration">
                                JSON Integration Consumer 2
                            </option>
                            <option name="KernCountyIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.KernCountyIntegrationConsumer, NSG.CMS.Integration">
                                Kern County Integration Consumer
                            </option>
                            <option name="SonomaAdultIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.SonomaAdultIntegrationConsumer, NSG.CMS.Integration">
                                Sonoma Adult Integration Consumer
                            </option>
                            <option name="SonomaJuvenileIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.SonomaJuvenileIntegrationConsumer, NSG.CMS.Integration">
                                Sonoma Juvenile Integration Consumer
                            </option>
                            <option name="TjjdJuvenileIntegrationConsumer"
                                    value="NSG.CMS.Integration.Consumers.TjjdJuvenileIntegrationConsumer, NSG.CMS.Integration">
                                TJJD Juvenile Integration Consumer
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="maxReceivedMessageSize">Max Received Message Size</label>
                        <select id="maxReceivedMessageSize"
                                class="form-control"
                                [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.maxReceivedMessageSize"
                                name="maxReceivedMessageSize"
                                required>
                            <option name="131072 bytes"
                                    value="131072">
                                131072 bytes
                            </option>
                            <option name="262144 bytes"
                                    value="262144">
                                262144 bytes
                            </option>
                            <option name="524288 bytes"
                                    value="524288">
                                524288 bytes
                            </option>
                            <option name="1048576 bytes"
                                    value="1048576">
                                1 MB
                            </option>
                            <option name="10485760 bytes"
                                    value="10485760">
                                10 MB
                            </option>
                            <option name="104857600 bytes"
                                    value="104857600">
                                100 MB
                            </option>
                            <option name="209715200 bytes"
                                    value="209715200">
                                200 MB
                            </option>
                            <option name="524288000 bytes"
                                    value="524288000">
                                500 MB
                            </option>
                        </select>
                    </div>

                    <div class="checkbox">
                        <label for="postFormResultsOnComplete">
                            <input id="postFormResultsOnComplete"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.postFormResultsOnComplete"
                                   name="postFormResultsOnComplete"
                                   type="checkbox" />Post Form Results On Complete
                        </label>
                    </div>

                    <div class="checkbox">
                        <label for="postCasePlanResultsOnComplete">
                            <input id="postCasePlanResultsOnComplete"
                                   [(ngModel)]="tenant.tenantConfiguration.integrationConfiguration.postCasePlanResultsOnComplete"
                                   name="postCasePlanResultsOnComplete"
                                   type="checkbox" />Post Case Plan Results On Complete
                        </label>
                    </div>
                </div>

                <hr />
                <h3>SSRS Configuration</h3>

                <div class="form-group">
                    <label for="ssrsBaseUrlInput">Base URL</label>
                    <input id="ssrsBaseUrlInput"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.baseUrl"
                           name="ssrsBaseUrlInput" />
                </div>

                <div class="form-group">
                    <label for="ssrsAuthType">Authorization Type</label>
                    <select id="ssrsAuthType"
                            class="form-control"
                            [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.authType"
                            name="ssrsAuthType"
                            required>
                        <option name="UsernamePassword"
                                value="0">
                            Username / Password
                        </option>
                        <option name="Application"
                                value="1">
                            Application
                        </option>
                    </select>
                </div>

                <div [hidden]="+(tenant.tenantConfiguration.ssrsConfiguration.authType) !== 0"
                     class="form-group">
                    <label for="username">Username</label>
                    <input id="username"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.username"
                           name="username" />
                </div>

                <div [hidden]="+(tenant.tenantConfiguration.ssrsConfiguration.authType) !== 0"
                     class="form-group">
                    <label for="password">Password</label>
                    <input id="password"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.password"
                           name="password" />
                </div>

                <div class="form-group">
                    <label for="baseFolder">Base Folder</label>
                    <input id="baseFolder"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.baseFolder"
                           name="baseFolder" />
                </div>

                <div class="form-group">
                    <label for="aggregateReportsPath">Aggregate Reports Path</label>
                    <input id="aggregateReportsPath"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.aggregateReportsPath"
                           name="aggregateReportsPath" />
                </div>

                <div class="form-group">
                    <label for="individualReportsPath">Individual Reports Path</label>
                    <input id="individualReportsPath"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.individualReportsPath"
                           name="individualReportsPath" />
                </div>

                <div class="form-group">
                    <label for="casePlanReportsPath">Case Plan Reports Path</label>
                    <input id="casePlanReportsPath"
                           class="form-control"
                           [(ngModel)]="tenant.tenantConfiguration.ssrsConfiguration.casePlanReportsPath"
                           name="casePlanReportsPath" />
                </div>

                <hr />
                <h3>Forms Configuration</h3>

                <div class="form-group">
                    <label for="formConfigurationText">Form Configurations</label>
                    <textarea id="formConfigurationText"
                              class="form-control"
                              [(ngModel)]="tenant.formConfigurationText"
                              name="formConfigurationText"
                              rows="10"
                              cols="100">
        </textarea>
                </div>

                <hr />
                <h3>Jobs Configuration</h3>

                <div class="form-group">
                    <label for="triggerConfigurationText">Job Triggers</label>
                    <textarea id="triggerConfigurationText"
                              class="form-control"
                              [(ngModel)]="tenant.triggerConfigurationText"
                              name="triggerConfigurationText"
                              rows="10"
                              cols="100">
</textarea>
                </div>

                <div class="menu">
                    <button type="button"
                            routerLink="../.."
                            class="btn btn-sm btn-secondary">
                        Cancel
                    </button>
                    <button type="submit"
                            class="btn btn-sm btn-primary"
                            (click)="save()">
                        Save
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="showJson"
             class="col-lg-6">
            <pre class="alert alert-secondary json-preview">{{tenant | json}}</pre>
        </div>
    </div>

    <div *ngIf="!tenant?.isNew()"
         class="danger-zone alert alert-danger">
        <button class="btn btn-danger"
                (click)="delete()">Delete Tenant</button>
    </div>

</div>