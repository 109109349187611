<div class="content"
     role="main">
    <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments">
                        <span class="">Deployments</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a class="link-body-emphasis text-decoration-none"
                       routerLink="/deployments/{{deploymentId}}">{{deploymentName}}</a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="link-body-emphasis fw-normal">
                        Status Updates
                    </span>
                </li>
            </ol>
        </nav>
    </div>
    <!-- <h2 class="pageName">Status History</h2> -->
    <!--<pre>Deployments list has: *{{ deploymentList | json }}*</pre>-->
    <div class="menu">
        <button *ngIf="statusSummaries && statusSummaries.length > 0"
                class="btn btn-sm btn-outline-danger float-end"
                (click)="deleteAllStatusSummaries()">Delete All History</button>
    </div>
    <div class="statusTable">
        <table *ngIf="statusSummaries && statusSummaries.length > 0">
            <thead>
                <tr>
                    <th>Status Date</th>
                    <th>Code Version</th>
                    <th>DB Version</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor
                             let-summary
                             [ngForOf]="statusSummaries">
                    <tr>
                        <td>{{summary.statusDate}}</td>
                        <td>{{summary.codeVersion}}</td>
                        <td>{{summary.dbVersion}}</td>
                        <td>
                            <button class="btn btn-link"
                                    (click)="deleteStatusSummary(summary)">
                                Delete
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <ul>
                                <ng-template ngFor
                                             let-tsu
                                             [ngForOf]="summary.tenantStatusUpdates">
                                    <li>
                                        {{tsu.name}} - {{tsu.subjectCount}} Subjects and
                                        {{tsu.userCount}} Users
                                        - DB {{tsu.isCurrentDb ? 'is current': 'version is ' +
                                        tsu.dbVersion
                                        }} Data Check: {{!tsu.dataValidationResult ? 'N/A' : tsu.dataValidationResult}} at
                                        {{tsu.modifiedDate}}
                                    </li>
                                </ng-template>
                            </ul>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <div *ngIf="!statusSummaries || statusSummaries.length === 0"
             class="statusTable">
            <p>No status history found.</p>
        </div>
    </div>
</div>
