import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantSummary } from '../models/tenant';
import { DeploymentsService } from '../services/deployments.service';
import { TenantsService } from '../services/tenants.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-tenant-dbs',
    templateUrl: './tenant-dbs.component.html',
    styleUrls: ['./tenant-dbs.component.scss']
})
export class TenantDbsComponent implements OnInit, OnDestroy {

    public tenants: TenantSummary[] = [];
    deploymentId: number | undefined;
    deploymentName = '';

    private subs = new SubSink();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _tenantsService: TenantsService,
        private _deploymentsService: DeploymentsService
    ) { }

    ngOnInit(): void {
        this.subs.sink = this._route.data.subscribe(
            ({ deployment }) => {
                if (deployment) {
                    this.deploymentId = deployment.deploymentId;
                    this.deploymentName = deployment.name;
                    this.tenants = deployment.tenants;
                }
            });
    }

    // TODO: Upgrade the prompts and toast with a better UI option
    upgradeTenantDb(tenant: TenantSummary) {
        if (confirm(`Are you sure you want to upgrade the database for tenant "${tenant?.name}"?`)) {
            this.subs.sink = this._tenantsService.upgradeTenantDb(tenant.deploymentId, tenant.tenantId).subscribe({
                next: () => {
                    alert(`Database upgrade for tenant "${tenant.name}" was started successfully.`);
                },
                error: (error) => {
                    alert(`Database upgrade for tenant "${tenant.name}" failed to start.  Error message: ${error ? error.message : 'unknown'}`);
                }
            });
        }
    }

    // TODO: Upgrade the prompts and toast with a better UI option
    upgradeAllTenantDbs() {
        if (!this.deploymentId) {
            console.error('No deploymentId found');
            return;
        }

        if (confirm(`Are you sure you want to upgrade all tenant databases for deployment "${this.deploymentName}"?`)) {
            this.subs.sink = this._deploymentsService.upgradeAllTenantDbs(this.deploymentId).subscribe({
                next: () => {
                    alert(`Database upgrades for all databases in deployment "${this.deploymentName}" were started successfully.`);
                },
                error: (error) => {
                    alert(`Database upgrades for all databases in deployment "${this.deploymentName}" failed to start.  Error message: ${error ? error.message : 'unknown'}`);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
