import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeploymentDetail } from '../models/deployment';
import { ActivatedRoute, Router } from '@angular/router';
import { DeploymentsService } from '../services/deployments.service';
import { SubSink } from 'subsink';
import { TenantSummary } from '../models/tenant';

@Component({
    selector: 'app-deployment-form',
    templateUrl: './deployment-form.component.html',
    styleUrls: ['./deployment-form.component.scss']
})
export class DeploymentFormComponent implements OnInit, OnDestroy {

    public deployment: DeploymentDetail | undefined;
    private subs = new SubSink();

    public tenantSearchText = '';
    public filteredTenants: TenantSummary[] | undefined = [];

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private deploymentService: DeploymentsService
    ) {

    }

    ngOnInit(): void {
        this.subs.sink = this._route.data.subscribe(
            ({ deployment }) => {
                if (deployment) {
                    this.deployment = deployment;
                    this.filteredTenants = deployment.tenants;
                } else {
                    this.deployment = new DeploymentDetail();
                }
            });
    }

    filterTenants() {
        if(this.tenantSearchText === '') {
            this.filteredTenants = this.deployment?.tenants;
        } else {
            this.filteredTenants = this.deployment?.tenants.filter(t => t.name.toLowerCase().includes(this.tenantSearchText.toLowerCase()));
        }
    }

    save() {
        const needsRefresh = this.deployment?.deploymentId === 0;

        this.subs.sink = this.deploymentService.saveDeployment(this.deployment).subscribe((d) => {
            if (needsRefresh) {
                this._router.navigate(['/deployments', d.deploymentId]);
            } else {
                this._router.navigate(['/deployments']);
            }
        });
    }

    delete() {

        if (confirm(`
Are you sure you want to delete this deployment: ${this.deployment?.name}?

This will delete all tenants and configurations associated with this deployment.

This cannot be undone.`)) {
            this.deploymentService.deleteDeployment(this.deployment?.deploymentId).subscribe(() => {
                this._router.navigate(['/deployments']);
            });
        }
    }

    // TODO: Upgrade the prompts and toast with a better UI option
    upgradeAllTenantDbs() {
        const deploymentId = this.deployment?.deploymentId;

        if (!deploymentId) {
            console.error('No deploymentId found');
            return;
        }

        const deploymentName = this.deployment?.name;

        if (confirm(`Are you sure you want to upgrade all tenant databases for deployment "${deploymentName}"?`)) {
            this.subs.sink = this.deploymentService.upgradeAllTenantDbs(deploymentId).subscribe({
                next: () => {
                    alert(`Database upgrades for all databases in deployment "${deploymentName}" were started successfully.`);
                },
                error: (error) => {
                    alert(`Database upgrades for all databases in deployment "${deploymentName}" failed to start.  Error message: ${error ? error.message : 'unknown'}`);
                }
            });
        }
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
