<div class="content"
     role="main">
    <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                <li class="breadcrumb-item active">
                    <span class="link-body-emphasis fw-normal text-decoration-none">Deployments</span>
                </li>
            </ol>
        </nav>
    </div>
    <!-- <h2 class="pageName">Manage Deployments</h2> -->
    <!--<pre>Deployments list has: *{{ deploymentList | json }}*</pre>-->
    <div class="menu">
        <button class="btn btn-sm btn-primary"
                routerLink="/deployments/new">
            + Add
        </button>
    </div>
    <div class="deploymentTable">
        <table>
            <thead>
                <tr>
                    <th>Deployment Name</th>
                    <th>UID</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let deployment of deploymentList">
                    <td>
                        <button class="btn btn-link"
                                routerLink="/deployments/{{ deployment.deploymentId }}">
                            {{ deployment.name }}
                        </button>
                    </td>
                    <td>{{ deployment.uid }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
