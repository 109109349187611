import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeploymentSummary } from '../models/deployment';
import { SubSink } from 'subsink';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-deployment-list',
  templateUrl: './deployment-list.component.html',
  styleUrls: ['./deployment-list.component.scss']
})
export class DeploymentListComponent implements OnInit, OnDestroy {
  deploymentList: DeploymentSummary[] = [];

  private subs = new SubSink()

  constructor(
    private _route: ActivatedRoute,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
  }

  ngOnInit(): void {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.subs.sink = this._route.data.subscribe(
      ({ deployments }) => {
        if (deployments && deployments.deployments) {
          this.deploymentList = deployments.deployments;
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
